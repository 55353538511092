import {reactive, toRefs,defineComponent,onBeforeMount, onMounted, getCurrentInstance} from 'vue';
import EditTableItemUtil ,{IEditTableItemDataObj} from "./editTableItemUtil";
export default defineComponent ({
    name: "editTableItem",
    props:{
        ownerInst:{type: Object, default:()=>{return{}}},
        disabled:{type: Boolean, default:false},
        item:{type: Object, default:()=>{return{}}},
        refMap:{type: Object, default:()=>{return new Map()}},
        editTableItems:{type: Array, default:()=>{return[]}},
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IEditTableItemDataObj=reactive<IEditTableItemDataObj>({
            utilInst:{} as any,
            otherParams:{

            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new EditTableItemUtil(proxy,dataObj,props,context);
        })
        onMounted(async ()=>{
        })
        return{
            ...toRefs(dataObj)
        }
    }
});